<template>
  <div class="services-area ptb-70">
    <div class="container-fluid p-0">
      <div class="overview-box">
        <div class="overview-content">
          <div class="content left-content">
            <h2>Чому обирають нас?</h2>
            <div class="bar"></div>
            <p>
              «Пансіонат "Любов" - це другий дім для ваших рідних, де вони
              оточені турботою та любов’ю. Ми дуже уважно ставимось до потреб та
              побажань наших гостей а також до індивідуальних призначень
              спеціалістів.
            </p>

            <ul class="services-list">
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Консультації лікарів та
                  персональний підбір лікування</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Ретельний підхід до
                  чистоти та гігієни</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Свіжа та смачна їжа 4
                  рази на день</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Індивідуальний підбір
                  харчування для різних діагнозів</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Спеціалізоване сучасне
                  медичне обладнання</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Висококваліфікована
                  дбайлива позитивна команда професіоналів 24/7</span
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="overview-image">
          <div class="image">
            <img src="../../assets/images/businesses-3.webp" alt="image" />

            <!-- <div class="circle-img">
              <img src="../../assets/images/circle.png" alt="image" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesThree",
};
</script>
