<template>
  <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
      <div class="overview-box">
        <div class="overview-image">
          <div class="image">
            <img src="../../assets/images/businesses-2.webp" alt="image" />

            <!-- <div class="circle-img">
              <img src="../../assets/images/circle.png" alt="image" />
            </div> -->
          </div>
        </div>

        <div class="overview-content">
          <div class="content">
            <h2>Домашня атмосфера</h2>
            <div class="bar"></div>
            <p>
              Пріоритетом для нас є приємне та безтурботне життя ваших рідних.
              Ми завжди допомагаємо підтримувати гарний настрій і позитивний
              психологічний стан наших мешканців.
            </p>

            <ul class="services-list">
              <li>
                <span><i class="flaticon-check-mark"></i> Арт-терапія</span>
              </li>
              <li>
                <span><i class="flaticon-check-mark"></i> Фізичні вправи</span>
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Святкуємо всі свята</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Запрошуємо класичних
                  музикантів</span
                >
              </li>
              <li>
                <span><i class="flaticon-check-mark"></i> Настільні ігри</span>
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Позитивне
                  спілкування</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesTwo",
};
</script>
