<template>
  <div>
    <ErrorArea />
  </div>
</template>

<script>
import ErrorArea from "../error/ErrorArea";

export default {
  name: "Error",
  components: {
    ErrorArea,
  },
};
</script>
