<template>
  <div class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
      <div class="section-title">
        <h2>Переваги пансіонату Любов</h2>
        <div class="bar"></div>
        <p>
          Наша команда не просто персонал, а справжня сім’я професіоналів. На
          скільки відповідально ми ставимось до якості наших послуг, на стільки
          ж чуйно ми ставимось до кожної людини яка обирає провести старість з
          нами. Тому робимо все щоб ви та ваші рідні відчували нашу турботу
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-features-box">
            <div class="icon">
              <i class="flaticon-settings"></i>
            </div>

            <h3>Швидке заселення</h3>
            <p>Спрощена процедура без бюрократичних вимог</p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-features-box">
            <div class="icon bg-f78acb">
              <i class="flaticon-envelope-of-white-paper"></i>
            </div>

            <h3>Абсолютна безпека</h3>
            <p>
              Закрита територія з охороною, цілодобове відеоспостереження та
              пожежна безпека
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-features-box">
            <div class="icon bg-cdf1d8">
              <i class="flaticon-menu"></i>
            </div>

            <h3>Інноваційне обладнання</h3>
            <p>
              Спеціалізовані ліжка з протипролежневими матрацами, індивідуальні
              кнопки виклику персоналу, реабілітаційні тренажери, медичні
              поручні, інвалідні візки
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-features-box">
            <div class="icon bg-c679e3">
              <i class="flaticon-info"></i>
            </div>

            <h3>Регулярне обстеження лікарів</h3>
            <p>
              Терапевт, Геронтолог, Кардіолог, Психіатр, Психолог, Реабілітолог,
              Фізіотерапевт, Массажист та Мануальний терапевт
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-features-box">
            <div class="icon bg-eb6b3d">
              <i class="flaticon-cursor"></i>
            </div>

            <h3>Все включено</h3>
            <p>
              Смачна дієтична їжа, цілодобовий догляд, контроль прийому ліків,
              гігієнічні процедури, дезинфекція, зміна білизни, вологе
              прибирання, провітрювання, прогулянки, приємне дозвілля і домашня
              позивна атмосфера
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-features-box">
            <div class="icon">
              <i class="flaticon-alarm"></i>
            </div>

            <h3>Завжди на зв’язку</h3>
            <p>
              Візити рідних в будь який день і час та можливість відеозв’язку.
              Ми ж на телефоні з вами 24/7/365 надаємо всю необхідну інформацію
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeaturesTwo",
};
</script>
