<template>
  <div class="page-title-area item-bg2">
    <div class="container">
      <div class="page-title-content">
        <h2>Про пансіонат</h2>
        <p>Важлива інформація</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
};
</script>
