<template>
  <div class="team-area ptb-70 bg-f7fafd">
    <div class="container">
      <div class="section-title">
        <h2>Висококваліфокована команда</h2>
        <div class="bar"></div>
        <p>
          В наших будинка ще дуже багато персоналу, які не представлені нижче.
          Але вони також мають вищу освіту
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img src="../../assets/images/team-img1.webp" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Писарчук Юрій Володимирович</h3>
              <span>Гериатр</span>
              <p>
                Лікар-геріатр надає медичну (терапевтичну) допомогу літнім та
                старим людям. Його мета – допомогти старіючій людині якомога
                довше зберегти незалежний спосіб життя.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img src="../../assets/images/team-img2.webp" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Меримерина Вікторія Йосифівна</h3>
              <span>Психотерапевт, психіатр</span>
              <p>
                Допомога лікаря психіатра може знадобитися в різних ситуаціях.
                Подолати депресивні стани, тривожні розлади, проблеми з
                ідентифікацією особистості та ін.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img src="../../assets/images/team-img3.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Михальчук Світлана Сергієвна</h3>
              <span>Психолог, Арт терапевт</span>
              <p>
                Арт терапія вкрай важлива в будинках для людей похилого віку,
                тому що люди похилого віку гостро відчувають потребу в
                спілкуванні, їм життєво необхідно «заняття по душі», щоб
                заповнити «порожнечу», що утворилася, — вільний час, який раніше
                вони витрачали на сім'ю і роботу.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="single-team-member">
            <div class="member-image">
              <img src="../../assets/images/team-img4.jpg" alt="image" />

              <ul class="social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
              </ul>
            </div>

            <div class="member-content">
              <h3>Вишку Георгій Михайлович</h3>
              <span
                >Реабілітолог, масажист вищої категорії, викладач лікувального
                масажу</span
              >
              <p>
                Учень та послідовник академіка В. Н. Набойченко, володіє
                західними та східними техніками мануальної терапії.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamArea",
};
</script>
