<template>
  <div class="page-title-area item-bg1">
    <div class="container">
      <div class="page-title-content">
        <h2>Контакти</h2>
        <p>Вся необхідна інформація, щоб зв'язатись з нами</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
};
</script>
