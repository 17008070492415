<template>
  <div class="page-title-area item-bg1">
    <div class="container">
      <div class="page-title-content">
        <h2>Відповіді на постійні запитання</h2>
        <p>Можливо вам буде цікаво дізнатись</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
};
</script>
