<template>
  <div class="account-create-area">
    <div class="container">
      <div class="account-create-content">
        <h2>Відповіді на часті запитання</h2>
        <p>Може серед них буде і ваше?</p>
        <router-link to="/faq" class="btn btn-primary">
          Переглянути
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountCreate",
};
</script>
