<template>
  <div class="services-area ptb-70">
    <div class="container-fluid p-0">
      <div class="overview-box">
        <div class="overview-content">
          <div class="content left-content">
            <h2>Наші послуги незамінні</h2>
            <div class="bar"></div>
            <p>
              Європейська якість догляду, та прогресивна методика з багаторічним
              досвідом, дозволяють нам надавати першокласні послуги для комфорту
              та безпеки ваших рідних.
            </p>

            <ul class="services-list">
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Післяопераційний
                  догляд</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Реабілітація після
                  інсульту та інфаркту</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Гальмування
                  деменції</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Догляд за лежачими</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Відновлення після
                  травм</span
                >
              </li>
              <li>
                <span
                  ><i class="flaticon-check-mark"></i> Догляд за людьми з
                  віковими розладами</span
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="overview-image">
          <div class="image">
            <img
              src="../../assets/images/businesses-1.jpg"
              alt="Дідусь обіймає бабусю"
            />

            <!-- <div class="circle-img">
              <img src="../../assets/images/circle.png" alt="image" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
